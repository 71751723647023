import { list } from "~/lib/api/logos";
import { keepPreviousData } from "@tanstack/vue-query";
import { useCursorPaginationControls } from "@smakecloud/smake-use";
import type { LogoFilter } from "~/lib/api/types/logos";

export const useLogoSearchStore = defineStore("logoSearchStore", () => {
    const searchPageVisited = ref<boolean>(false);
    const disableSearchWatcher = ref<boolean>(false);

    const currentCursor = ref<string>();
    const searchString = ref<string>();
    const perPage = ref<number>(10);
    const filter = ref<LogoFilter>({});

    const queryKey = [
        "merchantApi.getLogos",
        currentCursor,
        searchString,
        perPage,
        filter,
    ];

    const { isFetching, isError, data, error, refetch } = useQuery({
        queryKey: queryKey,
        queryFn: () =>
            list({
                cursor: currentCursor.value,
                search: searchString.value,
                per_page: perPage.value,
                filter: filter.value,
            }),
        placeholderData: keepPreviousData,
    });

    const logos = computed(() => data.value?.data ?? []);

    const changeSearch = (search?: string) => {
        currentCursor.value = undefined;
        searchString.value = search ?? "";
    };

    return {
        //TODO: delete while editing: [STR-3238]
        customerId: 0,

        searchPageVisited,
        logos,
        isFetching,
        isError,
        error,
        changeSearch,
        searchString,
        currentCursor,
        filter,
        ...useCursorPaginationControls(data, currentCursor),
        disableSearchWatcher,
        perPage,
        refetch,
    };
});
